<template>
  <div class="sustainability">
    <div class="main-bg">
      <img src="./../assets/sus_main_bg.jpg" alt="ScoreOne" />
    </div>
    <div class="main-content">
      <div class="content-list">
        <div>
          <img
            src="./../assets/sus_commityed_to_sustainability.png"
            alt="Commityed to Sustainability"
          />
          <article>
            <h3>{{$t('Sustainability.main_content.first_title')}}</h3>
            <p>{{$t('Sustainability.main_content.first_text')}}</p>
          </article>
        </div>
        <div>
          <img
            src="./../assets/sus_reasonable_and_responsible.png"
            alt="Reasonable and responsible"
          />
          <article>
            <h3>{{$t('Sustainability.main_content.second_title')}}</h3>
            <p>{{$t('Sustainability.main_content.second_text')}}</p>
          </article>
        </div>
        <div>
          <img
            src="./../assets/sus_combining_algorithms_and_human_checks.png"
            alt="Combining Algorithms&Human Checks"
          />
          <article>
            <h3>{{$t('Sustainability.main_content.third_title')}}</h3>
            <p>{{$t('Sustainability.main_content.third_text')}}</p>
          </article>
        </div>
      </div>
    </div>
    <div class="blue-bar">
      <h3>{{$t('Sustainability.bar_title')}}</h3>
    </div>
    <div class="users">
      <div class="users-content">
        <ul>
          <li v-for="(item, index) in users" :key="index">
            <div class="avatar">
              <img
                :src="item.avatarUrl"
                :alt="item.name"
                @click="currentUser = item.name"
              />
            </div>
            <h3>{{ item.name }}</h3>
          </li>
        </ul>
        <transition-group mode="out-in" enter-active-class="fadeInUp" leave-active-class="fadeOutDown" :duration="{enter: 1000, leave: 500}">
          <div
            class="users-case animated"
            v-for="item in users"
            :key="item.id"
            v-show="item.name === currentUser"
          >
            <div>
              <img :src="item.caseImgUrl" :alt="item.name + ' cases'" />
              <p>{{ $t('Sustainability.users.hint1') }}<br />{{ $t('Sustainability.users.hint2') }}</p>
            </div>
            <p>{{ $t(item.caseDedails) }}</p>
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sustainability',
  data: function() {
    return {
      users: [
        {
          id: 'ID01',
          name: 'Melvie',
          avatarUrl: require('./../assets/sus_users_Melvie.png'),
          caseImgUrl: require('./../assets/sus_users_Melvie.png'),
          caseDedails: 'Sustainability.users.Melvie'
        },
        {
          id: 'ID02',
          name: 'April',
          avatarUrl: require('./../assets/sus_users_April.png'),
          caseImgUrl: require('./../assets/sus_users_April.png'),
          caseDedails: 'Sustainability.users.April'
        },
        {
          id: 'ID03',
          name: 'John',
          avatarUrl: require('./../assets/sus_users_John.png'),
          caseImgUrl: require('./../assets/sus_users_John.png'),
          caseDedails: 'Sustainability.users.John'
        }
      ],
      currentUser: 'Melvie'
    }
  }
}
</script>

<style lang="scss">
.sustainability {
  width: 100%;
  .main-bg {
    width: 100%;
    min-width: 1200px;
    img {
      width: 100%;
      display: block;
    }
  }
  .main-content {
    width: 100%;
    .content-list {
      width: 1200px;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 80px 50px;
      > div {
        img {
          width: 200px;
          height: auto;
          margin-right: 98px;
        }
        article {
          display: inline-block;
          vertical-align: top;
          width: 780px;
          color: #323232;
          h3 {
            font: normal bold 26px Arial;
            padding: 16px 0;
          }
          p {
            font: normal 500 16px/18px Arial;
            letter-spacing: 1px;
            text-align: justify;
          }
        }
      }
      > div:nth-child(2) {
        margin: 60px 0;
      }
    }
  }
  .blue-bar {
    width: 100%;
    min-width: 1200px;
    height: 145px;
    background: #55b6fa;
    h3 {
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      color: #fff;
      font: normal bold 46px/145px Arial;
    }
  }
  .users {
    width: 100%;
    .users-content {
      width: 1200px;
      height: 800px;
      overflow: hidden;
      box-sizing: border-box;
      margin: 0 auto;
      text-align: center;
      padding: 120px 100px;
      ul {
        display: flex;
        justify-content: flex-start;
        li {
          margin-right: 55px;
          .avatar {
            width: 120px;
            height: 120px;
            border: 1px solid #dedede;
            border-radius: 50%;
            overflow: hidden;
            img {
              width: 100%;
              height: auto;
              cursor: pointer;
            }
          }
          h3 {
            font: normal bold 20px Arial;
            color: #585858;
            padding: 25px;
          }
        }
      }
      .users-case {
        background: #f3f3f3;
        padding: 20px 25px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          img {
            width: 230px;
            height: auto;
            border: 1px solid #dedede;
            border-radius: 8px;
          }
          p {
            width: 230px;
            box-sizing: border-box;
            font-size: 15px;
            text-align: left;
          }
        }
        p {
          width: 680px;
          text-align: justify;
          font: normal 500 18px/32px Arial;
          color: #585858;
        }
      }
    }
  }
}
</style>
